<template>
	<div class="distribution-record">
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'描述',dataIndex:'title'},
				{title:'金额',dataIndex:'change',slots:{customRender:'change'}},
				{title:'订单号',dataIndex:'order_num'},
				{title:'日期',dataIndex:'create_time'},
			]" >
				<template #change="{record}">
					<span v-if="record.status == 1" style="color:rgb(56 179 56)">+{{record.price}}</span>
					<span v-else style="color: orange;">-{{record.price}}</span>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getDistribution(info.page,e)}"
					@change="(e)=>{getDistribution(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import distributionModel from '@/api/addons/distribution'
import { useRoute } from 'vue-router'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
		})
		const opt = useRoute().query
		getDistrbution(1,state.info.limit)

		function getDistrbution(page,limit){
			distributionModel.getDisMoneyLog(page,limit,{user_id:opt.user_id},res=>state.info = {limit,...res})
		}

		return{
			...toRefs(state),
			getDistrbution
		}
	}
}
</script>

<style>
</style>
